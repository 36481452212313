import "./datatable.scss";
import { useState, useContext, useEffect } from "react";
import { db } from "../../firebase";
import { doc, deleteDoc, collection, addDoc, setDoc } from "firebase/firestore";
import moment from "moment";
import { Timestamp } from "firebase/firestore";
import TransactionContext from "../../context/transactionContext";
import Form from "./Form";
import EditAddWidget from "../widget/EditAddWidget";
import SubmitButton from "./SubmitButton";
import { AddOutlined, CloudDownloadOutlined } from "@mui/icons-material";
import MobileContext from "../../context/MobileContext";
import { AuthContext } from "../../context/AuthContext";
import CategoryIcon from "../icons/CategoryIcon";
import { useParams } from "react-router-dom";
import { lazy } from "react";
import { Suspense } from "react";
import LazyLoading from "../lazyloading/LazyLoading";
import { useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { CircularProgress } from "@mui/material";
import {
  convertArrayToObject,
  exportTransactionsToCSV,
  shadeColor,
} from "../../utils/utils";
import UserProjectsContext from "../../context/UserProjectsContext";
import EmojiIcon from "../icons/EmojiIcon";

const DataGrid = lazy(() =>
  import("@mui/x-data-grid").then((module) => ({ default: module.DataGrid }))
);
const DatatableMobile = lazy(() => import("./DatatableMobile"));

const Datatable = () => {
  const { sheetId } = useParams();
  const initialState = {
    accountId: "",
    category: "",
    vendor: "",
    description: "",
    amount: "",
    ...(sheetId && { sheetId: sheetId }),
  };
  const [dataInput, setDataInput] = useState(initialState);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const { data, setData } = useContext(TransactionContext);
  const isDarkMode = useSelector((state) => state.userSettings.isDarkMode);
  const { currentUser, onDemo } = useContext(AuthContext);
  const { projects } = useContext(UserProjectsContext);
  const { isMobile } = useContext(MobileContext);
  const userCategories = useSelector(
    (state) => state.userSettings.userCategories
  );
  const accounts = useSelector((state) => state.accounts.userAccounts);
  const currency = useSelector((state) => state.userSettings.currency);

  const [expenseData, setExpenseData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isOnEditAdd, setIsOnEditAdd] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState({
    showMessage: false,
    id: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState("");

  const accountsObj = convertArrayToObject(accounts);
  const projectsObj = convertArrayToObject(projects);

  const handleInput = (e) => {
    const id = e.target.id;
    let value = e.target.value;
    if (id === "amount") {
      const num = value.replace(/[^0-9$.,]/g, "").replace(/,/gi, "");
      const parts = num.split("."); //split whole number and decimal
      parts[0] = parts[0].split(/(?=(?:\d{3})+$)/).join(","); // split whole number with commas
      value = parts.join(".");
      // value = num.split(/(?=(?:\d{3})+$)/).join(",")
    }

    setDataInput({ ...dataInput, [id]: value });
  };

  const onChangeDate = (e) => {
    const newDate = moment(e.target.value).format("YYYY-MM-DD");
    setDate(newDate);
  };

  const handleDelete = async (id) => {
    //demo mode only
    if (!currentUser && onDemo) {
      setData(data.filter((item) => item.id !== id));
      return; // exit function
    }

    try {
      await deleteDoc(doc(db, "transactions", id));
    } catch (err) {}
    return;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let amountValue = parseFloat(dataInput.amount.replace(/,/gi, ""));

    // if demo mode only
    if (!currentUser && onDemo) {
      const newObject = {
        ...dataInput,
        id: new Date().valueOf().toString(),
        date: Timestamp.fromDate(new Date(date)),
        amount: Math.round(amountValue * 100) / 100,
        userId: "test",
        type: "expense",
      };

      if (isEditing) {
        // edit mode
        setData((prevState) => {
          const newState = prevState
            .map((obj) => {
              let oldObject = { ...obj };
              if (obj.id === editingId) {
                return newObject;
              } else {
                return oldObject;
              }
            })
            .sort((item1, item2) => item2.date.seconds - item1.date.seconds);
          return newState;
        });
      } else {
        // new mode
        setData((prevState) =>
          [...prevState, newObject].sort(
            (item1, item2) => item2.date.seconds - item1.date.seconds
          )
        );
      }

      setDataInput(initialState);
      setDate(moment().format("YYYY-MM-DD"));
      setIsSubmitting(false);
      setIsOnEditAdd(false);
      setIsEditing(false);
      setEditingId("");
      return; //exit function
    } // ------------------------ demo mode only ---------------------------

    // edit existing document
    if (isEditing) {
      try {
        await setDoc(doc(db, "transactions", editingId), {
          ...dataInput,
          date: Timestamp.fromDate(new Date(date)),
          amount: Math.round(amountValue * 100) / 100,
          userId: currentUser.uid,
          type: "expense",
        });
        setDataInput(initialState);
        setDate(moment().format("YYYY-MM-DD"));
        setIsSubmitting(false);
        setIsOnEditAdd(false);
        setIsEditing(false);
        setEditingId("");
      } catch (err) {
        console.log(err);
      }

      // new document
    } else {
      try {
        await addDoc(collection(db, "transactions"), {
          ...dataInput,
          date: Timestamp.fromDate(new Date(date)),
          amount: Math.round(amountValue * 100) / 100,
          userId: currentUser.uid,
          type: "expense",
          ...(sheetId && { sheetId: sheetId }), //create this field only when sheetId is not undefined
        });
        setDataInput(initialState);
        setDate(moment().format("YYYY-MM-DD"));
        setIsSubmitting(false);
        setIsOnEditAdd(false);
        setIsEditing(false);
        setEditingId("");
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleEdit = (id) => {
    setIsOnEditAdd(true);
    setIsEditing(true);
    setEditingId(id);
    const editData = expenseData.find((item) => item.id === id);
    const myDate = moment(new Date(editData.date.seconds * 1000)).format(
      "YYYY-MM-DD"
    );
    setDate(myDate);

    setDataInput({
      accountId:
        accounts.find((item) => item.id === editData.accountId)?.id || "",
      category: editData.category,
      vendor: editData.vendor,
      description: editData.description,
      amount: editData.amount.toLocaleString("en-US"),
      ...(editData.sheetId && { sheetId: editData.sheetId }),
    });
  };
  const handleClose = () => {
    setIsOnEditAdd(false);
    setIsEditing(false);
    setEditingId("");
    setDataInput(initialState);
    setDate(moment().format("YYYY-MM-DD"));
  };

  const handleExportCSV = () => {
    setIsExporting(true);
    exportTransactionsToCSV("expense", expenseData, accounts);
    setIsExporting(false);
  };

  const dataColumns = [
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 105,
      align: "center",
      headerAlign: "center",
      type: "date",
      valueGetter: (params) => {
        const { seconds } = params.value;
        const date = new Date(seconds * 1000);
        return date;
      },
      valueFormatter: (params) => {
        return params.value?.toLocaleDateString();
      },
    },
    {
      field: "accoundId",
      headerName: "Account",
      type: "singleSelect",
      valueOptions: accounts.map((item) => item.name),
      flex: 2,
      minWidth: 130,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const account = accountsObj[params.row.accountId];
        return (
          <div
            className={"cellWithColor"}
            style={{
              color: account?.color
                ? shadeColor(account?.color, isDarkMode ? 20 : -20)
                : "",
              backgroundColor: account?.color + "1A",
              border: `1px solid ${account?.color}33`,
            }}
          >
            {account && account.name}
          </div>
        );
      },
      valueGetter: (params) => {
        const accountName = accountsObj[params.row.accountId]?.name || "";
        return accountName;
      },
    },
    {
      field: "category",
      headerName: "Category",
      type: "singleSelect",
      valueOptions: userCategories.map((item) => item.label),
      flex: 2,
      minWidth: 130,
      align: "left",
      headerAlign: "center",
      renderCell: (params) => {
        const category = userCategories.find(
          (item) => item.label === params.row.category
        );
        return (
          <div className={`cellWithIcon ${params.row.category}`}>
            {category && <EmojiIcon emoji={category.icon} />}
            <div className="categoryLabel">{params.row.category}</div>
          </div>
        );
      },
    },
    {
      field: "vendor",
      headerName: "Vendor",
      flex: 2,
      minWidth: 130,
      align: "left",
      headerAlign: "center",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 4,
      minWidth: 130,
      headerAlign: "center",
      renderCell: (params) => {
        const project = projectsObj[params.row.sheetId];
        return (
          <div className="descriptionCell">
            {project && (
              <div className="project">
                <span className="projectIcon"></span>
                {project.name}
              </div>
            )}
            <div className="categoryLabel">{params.row.description}</div>
          </div>
        );
      },
      valueGetter: (params) => {
        const project = projectsObj[params.row.sheetId];
        return `${project?.name}: ${params.row.description}`;
      },
    },

    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      flex: 2,
      minWidth: 100,
      headerAlign: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const valueFormatted = Number(params.value).toLocaleString("en-US");
        return `${currency.symbol} ${valueFormatted}`;
      },
    },
    // {
    //   field: "type",
    //   headerName: "Type",
    //   flex: 1,
    //   minWidth: 100,
    //   align: 'center',
    //   headerAlign: 'center',
    //   renderCell: (params) => {
    //     return (
    //       <div className={`cellWithType ${params.row.type}`}>
    //         {params.row.type}
    //       </div>
    //     );
    //   },
    // },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      minWidth: 110,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="editButton"
              onClick={() => {
                handleEdit(params.row.id);
              }}
            >
              Edit
            </div>
            <div
              className="deleteButton"
              onClick={() => {
                setConfirmDelete({ showMessage: true, id: params.row.id });
              }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (!sheetId) {
      setExpenseData(data);
    } else {
      setExpenseData(data.filter((item) => item.sheetId === sheetId));
    }
  }, [sheetId, data]);

  return (
    <div className="datatable">
      {/* <div className="topContent">
        {!isOnEditAdd && (
          <div className="datatableInputs">
            <Form
              handleSubmit={handleSubmit}
              date={date}
              onChangeDate={onChangeDate}
              handleInput={handleInput}
              categories={userCategories}
              dataInput={dataInput}
              type={"expense"}
            />
            <SubmitButton
              isSubmitting={isSubmitting}
              isEditing={isEditing}
              type={"expense"}
            />
          </div>
        )}
      </div> */}
      <div className="bottomContent">
        <div className="datatableTitle">
          Expense Records
          <div className="topButtons">
            <button onClick={handleExportCSV} className="exportButton">
              {isExporting ? (
                <CircularProgress size={"1.275rem"} className="spinner" />
              ) : (
                <CloudDownloadOutlined className="icon" />
              )}
              CSV
            </button>
            <button
              className="buttonContainer"
              onClick={() => setIsOnEditAdd(true)}
            >
              {isSubmitting ? (
                <CircularProgress size={"1.275rem"} className="spinner" />
              ) : (
                <>
                  {!isEditing && <AddOutlined className="icon" />}
                  <span>Add New</span>
                </>
              )}
            </button>
          </div>
        </div>
        {/* desktop version */}
        {!isMobile && (
          <div className="datagridContainer">
            <Suspense fallback={<LazyLoading />}>
              <DataGrid
                className="datagrid"
                rows={expenseData}
                columns={dataColumns.concat(actionColumn)}
                pageSize={20}
                rowsPerPageOptions={[20]}
                sx={{
                  "& .MuiDataGrid-row:hover": {
                    backgroundColor: isDarkMode ? "#2d2d2d" : "#e1e5ee",
                  },
                }}
              />
            </Suspense>
          </div>
        )}

        {/* mobile version */}
        {isMobile && (
          <Suspense fallback={<LazyLoading />}>
            <DatatableMobile
              data={expenseData}
              handleEdit={handleEdit}
              userCategories={userCategories}
              currency={currency}
            />
          </Suspense>
        )}

        {/* Edit Add Form */}
      </div>
      <AnimatePresence>
        {isOnEditAdd && (
          <EditAddWidget
            handleSubmit={handleSubmit}
            date={date}
            onChangeDate={onChangeDate}
            handleInput={handleInput}
            categories={userCategories}
            dataInput={dataInput}
            setDataInput={setDataInput}
            setIsOnEditAdd={setIsOnEditAdd}
            handleClose={handleClose}
            isSubmitting={isSubmitting}
            isEditing={isEditing}
            editingId={editingId}
            type={"expense"}
            setConfirmDelete={setConfirmDelete}
            sheetId={sheetId}
          />
        )}
      </AnimatePresence>
      <div className="add-toggle" onClick={() => setIsOnEditAdd(true)}>
        <AddOutlined className="icon" />
      </div>
      <AnimatePresence>
        {confirmDelete.showMessage && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { duration: 0.4, ease: [0.36, 0.66, 0.04, 1] },
            }}
            exit={{
              opacity: 0,
              transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
            }}
            className="confirmDelete"
          >
            <motion.div
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{
                scale: 1,
                opacity: 1,

                transition: {
                  duration: 0.4,
                  type: "spring",
                  ease: [0.36, 0.66, 0.04, 1],
                },
              }}
              exit={{
                scale: 0.8,
                opacity: 0,
                transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
              }}
              className="confirmDeleteContainer"
            >
              <h1>Are you sure you want to delete record?</h1>
              <div className="row">
                <button
                  className="btn-cancel"
                  onClick={() =>
                    setConfirmDelete({ showMessage: false, id: "" })
                  }
                >
                  Cancel
                </button>
                <button
                  className="deleteButton"
                  onClick={() => {
                    handleDelete(confirmDelete.id);
                    setConfirmDelete({ showMessage: false, id: "" });
                    handleClose();
                  }}
                >
                  Delete
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Datatable;
